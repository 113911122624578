import React, { useState } from "react"
import { Row, Col, Container, Form, FormControl, FormGroup, FormLabel, Button } from "react-bootstrap"

import "../styles/style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

// You can delete this file if you're not using it
const PitchPage = () => {
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setSubmitted(true);
    // eslint-disable-next-line no-undef
    const data = new FormData(event.target);
    // eslint-disable-next-line no-undef
    fetch('https://data.accelerate.games/pitch', {
      method: 'POST',
      body: data,
    });
  };

  let form;
  if (submitted) {
    form = (
      <div className={"form-confirmation"}>Thank you for submitting your pitch.<br/>We will respond as soon as possible.</div>
    );
  } else  {
    form = (<Form noValidate validated={validated} onSubmit={handleSubmit}>
      <FormGroup>
        <label>Your Name</label>
        <FormControl required type="name" name="name"
                     style={{
                       backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==")',
                       backgroundRepeat: 'no-repeat',
                       backgroundAttachment: 'scroll',
                       backgroundSize: '16px 18px',
                       backgroundPosition: '98% 50%',
                       cursor: 'auto',
                     }}/>
        <Form.Control.Feedback type="invalid">
          Your Name cannot be empty.
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Contact Email</FormLabel>
        <FormControl required type="email" name="email"/>
        <Form.Control.Feedback type="invalid">
          Please enter a valid email.
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Game Name</FormLabel>
        <FormControl required type="name" name="game"/>
        <Form.Control.Feedback type="invalid">
          The Game Name cannot be empty.
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Message</FormLabel>
        <FormControl as="textarea" required name="message" rows="3"
                     placeholder="Let us know how we can help."/>
        <Form.Control.Feedback type="invalid">
          Really let us know how we can help :)
        </Form.Control.Feedback>
      </FormGroup>
      <Button type="submit" variant="primary">Send</Button>
    </Form>);
  }




  return (<Layout pageInfo={{pageName: "pitch"}}>
    <SEO title="Pitch" keywords={[]}/>
    <Container className={'pitch'}>
      <Row>
        <Col lg={6}>
          <div>
            <h1>Pitch your&nbsp;game</h1>
            <p>Accelerate is always looking to partner with amazing studios creating the next big hit. We evaluate your
              team as well as the game. We truly partner as publishers with independent studios through every step of
              the process.</p>
            <p> If you have a game (not just a game idea) and you are interested in partnering with us, just use the
              form to submit your game pitch. We will reply to every submission promptly.</p>
          </div>
        </Col>
        <Col lg={6} className="d-flex align-content-around flex-wrap">
          {form}
        </Col>
      </Row>
    </Container>
  </Layout>)
};

export default PitchPage
